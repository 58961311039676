import { definePreset } from '@primevue/themes';
// eslint-disable-next-line import-x/default
import Aura from '@primevue/themes/aura';
import { spacingTokens } from '@/styling/foundation/spacing.ts';
import { fontSizeTokens } from '@/styling/foundation/font-size.ts';
import { colorPrimaryTokens, colorSurfaceTokens, colorTokens } from '@/styling/foundation/color.ts';
import { accordionStyle } from '@/styling/components/accordion.ts';
import { buttonStyle } from '@/styling/components/button.ts';
import { cardStyle } from '@/styling/components/card.ts';
import { chipStyle } from '@/styling/components/chip.ts';
import { paginatorStyle } from '@/styling/components/paginator.ts';
import { panelStyle } from '@/styling/components/panel.ts';
import { menuStyle } from '@/styling/components/menu.ts';
import { progressSpinnerStyle } from '@/styling/components/progress-spinner.ts';
import { floatLabelStyle } from './components/floatLabel';
import { messageStyle } from './components/message';
import { dialogStyle } from './components/dialog';
import { inputTextStyle } from './components/inputText';
import { datePickerStyle } from './components/date-picker';
import { stepperStyle } from './components/stepper';
import { breakpointTokens } from './foundation/breakpoint';
import { galleriaStyle } from './components/galleria';
import { radioButtonStyle } from './components/radioButton';

export const primeTheme = {
    theme: {
        preset: definePreset(Aura, {
            extend: {
                root: {
                    color: colorTokens,
                    spacing: spacingTokens,
                    fontSize: fontSizeTokens,
                    breakpoints: breakpointTokens,
                    sectionGap: '160px',
                    spacingHeightNavBar: '56px',
                },
            },
            semantic: {
                primary: colorPrimaryTokens,
                colorScheme: {
                    light: {
                        formField: {
                            borderRadius: '{border.radius.sm}',
                        },
                        surface: colorSurfaceTokens,
                    },
                },
            },
            components: {
                accordion: accordionStyle,
                button: buttonStyle,
                card: cardStyle,
                chip: chipStyle,
                paginator: paginatorStyle,
                panel: panelStyle,
                progressspinner: progressSpinnerStyle,
                menu: menuStyle,
                floatlabel: floatLabelStyle,
                message: messageStyle,
                dialog: dialogStyle,
                inputtext: inputTextStyle,
                datepicker: datePickerStyle,
                stepper: stepperStyle,
                galleria: galleriaStyle,
                radiobutton: radioButtonStyle,
            },
        }),
        options: {
            darkModeSelector: false,
            cssLayer: true,
        },
    },
    unstyled: false,
};
