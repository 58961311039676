export const DEFAULT_ITEMS_PER_PAGE = 12;

export const MARKET_SWITCH_CONFIRMATION_DIALOG = 'MARKET_SWITCH_CONFIRMATION_DIALOG';

export const DEFAULT_ROWS_PER_TABLE = 5;

// eslint-disable-next-line no-magic-numbers
export const DEFAULT_ROW_AMOUNT_OPTIONS_TABLE = [5, 10, 20, 50];

export const BASIC_PAGE_TITLE = 'CONTAINEX PORTAL';
export const NO_DATA_DASH = '-';

export const MIN_SEARCH_CHARACTERS = 3;
