import { computed, type ComputedRef } from 'vue';
import { useNavigationAreaSwitchObserverStore } from '@/stores/navigation-area-track-observer';

export interface NavigationAreaSwitchObserverQuery {
    isChangingNavigationArea: ComputedRef<boolean>;
}

export interface NavigationAreaSwitchObserverAction {
    setIsChangingNavigationArea(newValue: boolean): void;
}

export function useNavigationAreaSwitchObserverQuery(): NavigationAreaSwitchObserverQuery {
    const store = useNavigationAreaSwitchObserverStore();

    return {
        isChangingNavigationArea: computed(() => store.isChangingNavigationArea),
    };
}

export function useNavigationAreaSwitchObserverAction(): NavigationAreaSwitchObserverAction {
    const store = useNavigationAreaSwitchObserverStore();

    return {
        setIsChangingNavigationArea(newValue: boolean): void {
            store.setIsChangingNavigationArea(newValue);
        },
    };
}
