import { type NavigationGuard } from 'vue-router';
import { useNavigationAreaSwitchObserverAction } from '@/composables/navigation-area-switch-observer';

// eslint-disable-next-line func-style
export const setNavAreaSwitchStart: NavigationGuard = () => {
    const actions = useNavigationAreaSwitchObserverAction();
    actions.setIsChangingNavigationArea(true);
};

// eslint-disable-next-line func-style
export const setNavAreaSwitchEnd: NavigationGuard = () => {
    const actions = useNavigationAreaSwitchObserverAction();
    actions.setIsChangingNavigationArea(false);
};
