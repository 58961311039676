<script setup lang="ts">
    import FloatLabel from 'primevue/floatlabel';
    import Message from 'primevue/message';
    import { ref, type Ref } from 'vue';
    import { componentStyle } from '@/styling/util/component-style.ts';

    defineProps<{
        label: string;
        errorMessage?: string;
    }>();

    const containerRef = ref(null);

    const inputRef = ref();
    const messageStyle = componentStyle({
        textFontWeight: '400',
    });

    function setInputRef(newRef: Ref<unknown>): void {
        inputRef.value = newRef;
    }

    defineExpose({
        innerRef: containerRef,
    });
</script>

<template>
    <div ref="containerRef" class="form-field-container">
        <FloatLabel variant="in">
            <slot :ref="setInputRef" />
            <label :for="inputRef">{{ label }}</label>
        </FloatLabel>
        <Message
            v-if="errorMessage != null && errorMessage !== ''"
            :dt="messageStyle"
            severity="error"
            size="small"
            variant="simple"
            >{{ errorMessage }}</Message
        >
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .form-field-container {
        padding-top: main.$spacing-5;
    }
</style>
