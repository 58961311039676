export const enum Navigation {
    Login = '/login',
    Signup = '/signup',
    SignupNew = '/signup/new',
    SignupNewSuccess = '/signup/new/success',
    SignupFastTrackSuccess = '/signup/fast-track/success',
    SignupFastTrackInquirySuccess = '/signup/fast-track/inquiry/success',
    SignupActivateNew = '/signup/activate/new',
    SignupActivateFastTrack = '/signup/activate/fast-track',
    SignupActivateSuccess = '/signup/activate/success',
    PasswordForgot = '/password/forgot',
    PasswordForgotSuccess = '/password/forgot/success',
    PasswordReset = '/password/reset',
    PasswordResetSuccess = '/password/reset/success',
    SalesProductList = '/sales/products',
    SalesProductListNew = '/sales/products/new',
    SalesProductListUsed = '/sales/products/used',
    RentalProductList = '/rental/products',
    RentalProductListSanitary = '/rental/products/sanitary',
    RentalProductListStorage = '/rental/products/storage',
    RentalProductListOffice = '/rental/products/office',
    SalesProductDetail = '/sales/products/detail',
    RentalProductDetail = '/rental/products/detail',
    SalesCart = '/sales/cart',
    RentalCart = '/rental/cart',
    Landing = '/',
    SalesCheckout = '/sales/cart/checkout',
    RentalCheckout = '/rental/cart/checkout',
    SalesCheckoutSuccess = '/sales/cart/checkout/success',
    RentalCheckoutSuccess = '/rental/cart/checkout/success',
    Users = '/account/users',
    Account = '/account',
    AccountSettings = '/account/settings',
    Employees = '/account/employees',
    NotFound = '/not-found',
    RentalNotFound = '/rental/products/not-found',
    SalesNotFound = '/sales/products/not-found',
    AccountNotFound = '/account/not-found',
    OrderList = '/account/orders',
    OrderDetail = '/accounts/orders/detail',
    OrderConfirmationList = 'accounts/order-confirmations',
    OrderConfirmationDetail = 'accounts/order-confirmations/detail',
    OfferList = '/account/offers',
    OfferDetail = '/account/offers/detail',
    InvoiceList = '/account/invoices',
    Leads = '/account/leads',
    LeadDetails = '/account/leads/detail',
    Changelog = '/changelog',
    Downloads = '/account/downloads',
    Impersonate = '/impersonate',
}
