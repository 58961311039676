import { type NavigationGuard } from 'vue-router';
import { useHead } from '@unhead/vue';

// eslint-disable-next-line func-style
export const preventCrawlerIndex: NavigationGuard = () => {
    useHead({
        meta: [
            {
                name: 'robots',
                content: 'noindex',
            },
        ],
    });
};
