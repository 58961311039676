import { type NavigationGuard } from 'vue-router';

// eslint-disable-next-line func-style
export const removeTrailingSlash: NavigationGuard = (to) => {
    if (to.fullPath.endsWith('/')) {
        return {
            path: to.fullPath.replace(/\/$/, ''),
        };
    }
};
