// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { watch } from 'vue';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { i18n } from '@/i18n/i18n.ts';
import { useTitle } from '@vueuse/core';
import { Navigation } from '@/router/navigation';
import { requireNotLoggedIn } from '@/router/guards/require-not-logged-in';
import { inferParams } from '@/router/guards/infer-params';
import { inferParamsIfMissing } from '@/router/guards/infer-params-if-missing';
import { initMarket } from '@/router/guards/init-market';
import { initCart } from '@/router/guards/init-cart';
import { requireLoggedIn } from '@/router/guards/require-logged-in';
import { requireCartHasItems } from '@/router/guards/require-cart-has-items';
import { initLanguage } from '@/router/guards/init-language';
import { initNavAreaByProvisionType, initNavAreaMyArea } from '@/router/guards/init-nav-area';
import { inferProvisionTypeAndFetchNewLeadsCount } from './guards/infer-provision-type-and-fetch-new-leads-count';
import { ensureProvisionType } from './guards/ensure-provision-type';
import { initReferrer } from './guards/init-referrer';
import { changelogRoutes } from './changelog-routes';
import { productRoutes } from './product-routes';
import { accountRoutes } from './account-routes';
import { cartRoutes } from './cart-routes';
import { passwordRoutes } from './password-routes';
import { signupRoutes } from './signup-routes';
import { assemblePageTitle } from '@/util/pageTitleUtils.ts';
import { requireLoggedInWhitelist } from '@/router/guards/require-logged-in-whitelist.ts';
import { removeTrailingSlash } from './guards/remove-trailing-slash';
import { preventCrawlerIndex } from './guards/prevent-crawler-index';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: withCodeParams([
        {
            path: '',
            component: async () => import('../views/NavBarView.vue'),
            children: [
                {
                    path: '',
                    name: Navigation.Landing,
                    redirect: {
                        name: Navigation.SalesProductList,
                    },
                    beforeEnter: [initNavAreaByProvisionType],
                },
                {
                    path: 'login',
                    name: Navigation.Login,
                    component: async () => import('../views/LoginView.vue'),
                    beforeEnter: [requireNotLoggedIn, initNavAreaMyArea],
                    meta: {
                        titleKey: 'TITLES.LOGIN',
                    },
                },
                {
                    path: 'impersonate',
                    name: Navigation.Impersonate,
                    component: async () => import('../views/ImpersonateView.vue'),
                    beforeEnter: [requireLoggedInWhitelist, initNavAreaMyArea],
                    meta: {
                        titleKey: 'TITLES.IMPERSONATE',
                    },
                },
                signupRoutes,
                passwordRoutes,
                accountRoutes,
                productRoutes,
                cartRoutes,
                changelogRoutes,
            ],
        },
        {
            path: 'sales/cart/checkout',
            name: Navigation.SalesCheckout,
            component: async () => import('../checkout/views/SaleCheckoutView.vue'),
            beforeEnter: [
                ensureProvisionType,
                requireLoggedIn,
                requireCartHasItems,
                initNavAreaByProvisionType,
                initCart,
            ],
            meta: {
                titleKey: 'TITLES.SALES_CART_CHECKOUT',
            },
        },
        {
            path: 'rental/cart/checkout',
            name: Navigation.RentalCheckout,
            component: async () => import('../checkout/views/RentalCheckoutView.vue'),
            beforeEnter: [
                ensureProvisionType,
                requireLoggedIn,
                requireCartHasItems,
                initNavAreaByProvisionType,
                initCart,
            ],
            meta: {
                titleKey: 'TITLES.RENTAL_CART_CHECKOUT',
            },
        },
        {
            path: ':pathMatch(.*)*',
            redirect: (to) => ({
                ...to,
                name: Navigation.SalesNotFound,
            }),
            beforeEnter: [preventCrawlerIndex],
        },
    ]),
    scrollBehavior(to, from) {
        if (from?.fullPath != null && to?.fullPath != null) {
            const basePathFrom = from.fullPath.split('?')[0];
            const basePathTo = to.fullPath.split('?')[0];
            const onlyQueryParametersChanged = basePathTo === basePathFrom;
            if (onlyQueryParametersChanged) {
                return;
            }
        }
        return { top: 0 };
    },
});

router.beforeEach(inferProvisionTypeAndFetchNewLeadsCount);
router.beforeResolve(initReferrer);

router.afterEach((to) => {
    const titleKey = to.meta?.titleKey;
    if (titleKey !== undefined && typeof titleKey !== 'string') {
        return;
    }
    updatePageTitle(titleKey);
    watch(i18n.global.locale, () => {
        updatePageTitle(titleKey);
    });
});

export default router;

function updatePageTitle(titleKey?: string): void {
    if (titleKey === undefined) {
        useTitle().value = assemblePageTitle();
    } else {
        const { t } = i18n.global;
        useTitle().value = assemblePageTitle(t(titleKey));
    }
}

function withCodeParams(children: RouteRecordRaw[]): RouteRecordRaw[] {
    return [
        {
            path: '/:market/:language',
            beforeEnter: [inferParamsIfMissing, initMarket, initLanguage, removeTrailingSlash],
            children,
        },
        {
            path: '/:pathMatch(.*)*',
            component: () => null,
            beforeEnter: [inferParams, removeTrailingSlash],
        },
    ];
}
