import { defineStore } from 'pinia';

interface NavigationAreaSwitchObserverState {
    isChangingNavigationArea: boolean;
}

export const useNavigationAreaSwitchObserverStore = defineStore('navigation-area-switch-observer', {
    state: (): NavigationAreaSwitchObserverState => ({
        isChangingNavigationArea: false,
    }),
    actions: {
        setIsChangingNavigationArea(newValue: boolean): void {
            this.isChangingNavigationArea = newValue;
        },
    },
});
