export const colorTokens = {
    white: '#ffffff',
    text: '#323232',
    textSecondary: '#6c757d',
    secondaryBlue: '#0a3d70',
    backgroundGrey: '#f2f4f6',
    backgroundBluegray: '#f7f8f9',
    backgroundLightgray: '#e5e7eb',
    chipBackgroundGray: '#64748b',
    successGreen: '#4cbb17',
    productNotAvailable: '#bd362f',
    textGray: '#808080',
    greenDark: '#51a351',
    secondary: '#6b6b6b',
    textBluegray: '#282e38',
    tagSecondary: '#eeeeee',
    backgroundTableHeader: '#f9fafb',
    backgroundLightblue: '#f0f7ff',
    borderLightgray: '#f3f4f6',
    borderGray: '#dee2e6',
    borderDarkGray: '#ced4da',
    toggleButtonBorder: '#bcc3cd',
    toggleButtonActive: '#00529e',
    orderSuccessful: '#3f7e3f',
    orderSuccessfulBg: '#51a3511f',
    errorRed: '#ff0000',
    discountRed: '#d82a22',
    yellow_500: '#eab308',
    orange: '#cf450d',
    outlinedButtonOrange: '#c4410c',
    primaryHoverBackground: '00529e0a',
    buttonDisabledBackground: '#eeeeee',
    buttonDisabledBorderColor: '#d1d1d1',
    buttonBlueGrayHalfTransparentHoverBackgroundColor: '#dadee380',
    horizontalMenuBackgroundDarkerColor: '#054a87',
    productCardCartButtonBorder: '#d1d5db',
    yellowSecondaryColor: '#ffdd00',
    primaryDarkerColor: '#07274A',

    // Chip colors (chips do not have severity property)
    statusGreenColor: '#336234',
    statusGreenBackground: '#e5f3e5',
    statusRedColor: '#c62828',
    statusRedBackground: '#f7e6e6',
    statusOrangeColor: '#9a3412',
    statusOrangeBackground: '#ffedd5',
    statusBlueColor: '#295bac',
    statusBlueBackground: '#ddeffc',
    statusGreyColor: '#556376',

    // Button colors
    buttonTextPrimaryHoverBackground: '#00529e0a',
    buttonSecondaryHoverBackground: '#cddcf2',

    // InputText colors
    inputTextDisabledBackground: '#f8f8f8',

    // Message colors
    messageInfoBackground: '#dfeeff',

    lightgrayBackground: '#f8f9fa',

    galleryImagePreviewToolbarBackground: '#fafafa',
    galleryImagePreviewToolbarBorder: '#e5e7eb',
    galleryImagePreviewToolbarIcon: '#00529E',
};

export const colorPrimaryTokens = {
    50: '#f2f6fa', // Select component
    100: '#c2d5e8', // Select component
    200: '#91b5d5',
    300: '#6194c3',
    400: '#3073b0',
    500: '#00529e', // ctx primary color
    600: '#006bcb', // primary color darker
    700: '#00396f',
    800: '#002d57',
    900: '#00213f',
    950: '#00529e0a', // primaryHoverBackground
};

// copied from (surfaces -> slate) https://github.dev/primefaces/primevue/blob/master/packages/themes/src/presets/aura/index.d.ts
export const colorSurfaceTokens = {
    0: '#ffffff',
    50: '#f8fafc',
    100: '#f5f5f5', // missing product image
    200: '#dee2e6', // border color
    300: '#cbd5e1',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#323232', // text color
    800: '#1e293b',
    900: '#0f172a',
    950: '#020617',
};
